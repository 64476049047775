import { motion } from 'framer-motion'
import {
  ArrowRight,
  ChevronDown,
  GraduationCap,
  Mail,
  MessageCircle,
  Quote,
  Sparkles,
  Star,
} from 'lucide-react'
import React from 'react'

const Hero = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.3,
      },
    },
  }

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
      },
    },
  }

  const floatingStars = Array(15).fill(null)

  return (
    <div className='min-h-screen bg-[#01003c] relative overflow-hidden'>
      {/* Animated background elements */}
      <div className='absolute inset-0'>
        {floatingStars.map((_, i) => (
          <motion.div
            key={i}
            className='absolute w-1 h-1 bg-blue-400/30 rounded-full'
            style={{
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
            }}
            animate={{
              y: [0, -20, 0],
              opacity: [0.3, 1, 0.3],
              scale: [1, 1.5, 1],
            }}
            transition={{
              duration: 3 + Math.random() * 2,
              repeat: Infinity,
              repeatType: 'reverse',
            }}
          />
        ))}
      </div>

      {/* Gradient orbs */}
      <motion.div
        className='absolute top-0 right-0 w-[1000px] h-[1000px] bg-blue-500/5 rounded-full blur-3xl'
        animate={{
          x: [0, 100, 0],
          y: [0, -100, 0],
          scale: [1, 1.2, 1],
        }}
        transition={{
          duration: 15,
          repeat: Infinity,
          repeatType: 'reverse',
        }}
      />
      <motion.div
        className='absolute -bottom-40 -left-40 w-[1000px] h-[1000px] bg-indigo-500/5 rounded-full blur-3xl'
        animate={{
          x: [0, -100, 0],
          y: [0, 100, 0],
          scale: [1, 1.2, 1],
        }}
        transition={{
          duration: 20,
          repeat: Infinity,
          repeatType: 'reverse',
        }}
      />

      <motion.div
        className='relative z-10'
        variants={containerVariants}
        initial='hidden'
        animate='visible'
      >
        {/* Header */}
        <motion.div
          className='text-center pt-12 lg:pt-16 px-4'
          variants={itemVariants}
        >
          <div className='flex items-center justify-center gap-4 mb-8'>
            <GraduationCap className='w-12 h-12 text-blue-400' />
            <h1 className='text-5xl lg:text-7xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 via-purple-400 to-blue-400'>
              The Orion School
            </h1>
          </div>
        </motion.div>

        {/* Main Content */}
        <div className='max-w-8xl mx-auto px-4 lg:px-8 py-12 lg:py-16'>
          <div className='grid lg:grid-cols-2 gap-12 lg:gap-20 items-start'>
            {/* Enhanced Image Section */}
            <motion.div
              className='order-2 lg:order-1 lg:sticky lg:top-8'
              variants={itemVariants}
            >
              <div className='relative group'>
                {/* Animated background effects */}
                {[...Array(4)].map((_, i) => (
                  <motion.div
                    key={i}
                    className='absolute inset-0 bg-gradient-to-r from-blue-500/10 to-purple-500/10 rounded-3xl blur-xl'
                    animate={{
                      scale: [1, 1.02, 1],
                      opacity: [0.3, 0.5, 0.3],
                      rotate: [0, 5, 0],
                    }}
                    transition={{
                      duration: 5 + i,
                      repeat: Infinity,
                      repeatType: 'reverse',
                      delay: i * 0.5,
                    }}
                  />
                ))}

                <div className='relative bg-gradient-to-br from-blue-900/50 to-purple-900/50 p-1 rounded-3xl'>
                  <div className='relative rounded-3xl overflow-hidden backdrop-blur-xl bg-white/5'>
                    {/* Image container with hover effects */}
                    <div className='relative p-8'>
                      <div className='absolute inset-0 bg-gradient-to-t from-[#01003c]/80 to-transparent z-10' />
                      <img
                        src='https://raw.githubusercontent.com/furqanistic/ogs-client/refs/heads/main/client/src/img/img.png'
                        alt='Prof. Waqas Zaheer'
                        className='rounded-2xl w-full h-[700px] object-cover object-center transform group-hover:scale-105 transition-transform duration-700 ease-out'
                      />
                      {/* Interactive elements overlay */}
                      <div className='absolute inset-0 bg-gradient-to-t from-[#01003c] via-transparent to-transparent z-20' />
                      <div className='absolute bottom-0 left-0 right-0 p-8 z-30'>
                        <motion.div
                          className='text-center'
                          variants={itemVariants}
                        >
                          <h2 className='text-4xl font-bold text-white mb-4 bg-clip-text text-transparent bg-gradient-to-r from-white to-blue-200'>
                            Dr. Waqas Zaheer
                          </h2>
                          <div className='flex items-center justify-center gap-3 mb-6'>
                            <Sparkles className='w-6 h-6 text-blue-400' />
                            <p className='text-2xl font-medium text-blue-400'>
                              Rector
                            </p>
                          </div>
                          <motion.button
                            className='px-8 py-4 bg-gradient-to-r from-blue-500 to-purple-500 rounded-full 
                                     flex items-center gap-3 mx-auto text-white font-medium text-lg group
                                     hover:shadow-lg hover:shadow-blue-500/25 transition-all duration-500'
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                          >
                            <MessageCircle className='w-6 h-6' />
                            Connect with Rector
                            <ArrowRight className='w-6 h-6 group-hover:translate-x-1 transition-transform duration-300' />
                          </motion.button>
                        </motion.div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>

            {/* Message Section with complete message */}
            <motion.div className='order-1 lg:order-2' variants={itemVariants}>
              <div className='bg-gradient-to-br from-blue-900/50 to-purple-900/50 rounded-3xl p-1'>
                <div className='backdrop-blur-xl bg-white/5 rounded-3xl p-8 lg:p-12 relative'>
                  <Quote className='w-16 h-16 text-blue-400/20 absolute top-8 right-8' />
                  <div className='space-y-8 text-blue-50/90'>
                    <motion.p
                      className='text-4xl font-medium text-white mb-8 leading-relaxed'
                      variants={itemVariants}
                    >
                      "Greetings, I'm Waqas Zaheer"
                    </motion.p>

                    <motion.div
                      className='space-y-6 leading-relaxed text-lg lg:text-xl'
                      variants={itemVariants}
                    >
                      <p>Dear Students, Parents, and Educators!</p>

                      <p>
                        It is with immense joy and pride that I extend a warm
                        welcome to each of you to The Orion School. As the
                        RECTOR, I am honored to lead this institution into a
                        realm of boundless possibilities.
                      </p>

                      <p>
                        The Orion School stands as a beacon of excellence,
                        committed to fostering not just academic achievement but
                        also the holistic development of every individual who
                        passes through our doors. Our dedicated team of
                        educators is passionately devoted to cultivating an
                        environment where curiosity, creativity, and critical
                        thinking thrive.
                      </p>

                      <p>
                        Here, we aim to nurture not just bright minds but also
                        compassionate hearts and resilient spirits. At The Orion
                        School, we believe in the power of collaboration,
                        innovation, and a deep-rooted commitment to lifelong
                        learning.
                      </p>

                      <p>
                        Together, let us embark on a journey where dreams are
                        nurtured, talents are honed, and aspirations are
                        realized. I invite you all to be an integral part of
                        this exciting chapter in education, where we shall
                        strive to make a positive impact on the world through
                        knowledge, integrity, and empathy.
                      </p>

                      <motion.div
                        className='border-l-4 border-blue-400 pl-8 py-6 bg-blue-900/20 rounded-r-2xl'
                        variants={itemVariants}
                      >
                        <p className='italic text-blue-300 text-xl'>
                          "Welcome aboard, and let us chart a path to success
                          and fulfillment together."
                        </p>
                      </motion.div>

                      <motion.div className='mt-12' variants={itemVariants}>
                        <p className='text-blue-300 text-lg'>Warm regards,</p>
                        <p className='font-bold text-white text-2xl mt-2'>
                          Waqas Zaheer
                        </p>
                        <p className='text-blue-400 text-lg'>
                          Rector, The Orion School
                        </p>
                      </motion.div>
                    </motion.div>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </motion.div>
    </div>
  )
}

export default Hero
