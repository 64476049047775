import { motion } from 'framer-motion'
import { Building2, UserCircle2, Users } from 'lucide-react'
import React from 'react'

const PortalLinks = () => {
  const portalData = [
    {
      title: 'Student Portal',
      icon: UserCircle2,
      color: 'bg-gradient-to-br from-violet-500 to-purple-600',
      link: 'https://faculty.theorionschool.com/',
    },
    {
      title: 'Faculty Portal',
      icon: Users,
      color: 'bg-gradient-to-br from-blue-500 to-cyan-600',
      link: 'https://faculty.theorionschool.com/',
    },
    {
      title: 'Management Portal',
      icon: Building2,
      color: 'bg-gradient-to-br from-emerald-500 to-teal-600',
      link: 'https://faculty.theorionschool.com/',
    },
  ]

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  }

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  }

  return (
    <div className='min-h-screen bg-[#0b0a45] py-12 px-4'>
      <div className='max-w-6xl mx-auto'>
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className='text-center mb-16'
        >
          <h1 className='text-4xl md:text-6xl font-bold text-white mb-4'>
            Welcome to Our Portals
          </h1>
          <p className='text-gray-300 text-lg md:text-xl'>
            Select your destination to get started
          </p>
        </motion.div>

        <motion.div
          variants={containerVariants}
          initial='hidden'
          animate='visible'
          className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 px-4'
        >
          {portalData.map((portal, index) => (
            <motion.div
              key={index}
              variants={itemVariants}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className='cursor-pointer'
              onClick={() => (window.location.href = portal.link)}
            >
              <div
                className={`${portal.color} rounded-2xl p-8 h-full shadow-xl hover:shadow-2xl transition-shadow duration-300`}
              >
                <div className='flex flex-col items-center space-y-6'>
                  <div className='p-4 bg-white/10 rounded-full'>
                    <portal.icon size={48} className='text-white' />
                  </div>
                  <div className='text-center'>
                    <h2 className='text-2xl font-bold text-white mb-2'>
                      {portal.title}
                    </h2>
                    <p className='text-white/80'>
                      Click to access the {portal.title.toLowerCase()}
                    </p>
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </div>
  )
}

export default PortalLinks
